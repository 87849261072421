import {BrowserExtensionSigningManager} from '@polymeshassociation/browser-extension-signing-manager';
import type {InjectedAccountWithMeta} from '@polkadot/extension-inject/types';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import {APP_NAME, POYLMESH_EXTN_NAME} from '../../utils/polymesh-consts';
import {NetworkInfo} from '@polymeshassociation/browser-extension-signing-manager/types';
import {Polymesh as PolymeshSDK} from '@polymeshassociation/polymesh-sdk';
import {
  LocalStroageUtil,
  POLYMESH_LOCAL_STORAGE,
} from '../../utils/local-storage-utils';
import {useToastContext} from '../toast-context';
import {Account, Identity} from '@polymeshassociation/polymesh-sdk/types';
import {AccountIdentityRelation} from '@polymeshassociation/polymesh-sdk/api/entities/Account/types';
import useLocalStorage from './useLocalStorage';
import {useRouter} from 'next/router';

interface PolymeshValue {
  initializeSigningManager: () => Promise<BrowserExtensionSigningManager>;
  connectWallet: () => Promise<void>;
  sdk: PolymeshSDK | undefined;
  logout: () => void;
  allAccountsWithMeta: InjectedAccountWithMeta[];
  setSelectedAccount: (account: string) => void;
  selectedAccount: string;
  identityLoading: boolean;
  identity: Identity | null;
  allIdentities: (Identity | null)[];
  allAccounts: string[];
  setRememberSelectedAccount: (shouldRemember: boolean) => void;
  errorDetails: {isError: boolean; message: string};
}
interface PolymeshProviderProps {
  children: ReactNode | React.ReactElement | React.ReactElement[];
}

const Polymesh = React.createContext<PolymeshValue | null>(null);

export const PolymeshProvider: React.FC<PolymeshProviderProps> = ({
  children,
}) => {
  const toast = useToastContext();
  const router = useRouter();
  const [sdk, setSdk] = useState<PolymeshSDK | undefined>();
  const [signingManager, setSigningManager] =
    useState<BrowserExtensionSigningManager | null>(null);
  const [allAccounts, setAllAccounts] = useState<string[]>([]);
  const [allAccountsWithMeta, setAllAccountsWithMeta] = useState<
    InjectedAccountWithMeta[]
  >([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [account, setAccount] = useState<Account | null>(null);

  const [allSigningAccounts, setAllSigningAccounts] = useState<Account[]>([]);
  const [identity, setIdentity] = useState<Identity | null>(null);
  const [allIdentities, setAllIdentities] = useState<(Identity | null)[]>([]);
  const [shouldRefreshIdentity, setShouldRefreshIdentity] = useState(true);
  const [identityLoading, setIdentityLoading] = useState(true);
  const [accountLoading, setAccountLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    isError: false,
    message: '',
  });
  const [walletConnectStatus, setWalletConnectionStatus] = useState(false);

  // Need to work on this code

  interface IChainMetadata {
    [key: string]: {
      metadata: `0x${string}`;
      specVersion: string;
      timestamp: string;
    };
  }

  const [initialized, setInitialized] = useState(false);

  const [nodeUrl, setNodeUrl] = useLocalStorage<string>(
    'rpcUrl',
    process.env.POLYMESH_NODE_URL || '',
  );
  const [middlewareUrl, setMiddlewareUrl] = useLocalStorage<string>(
    'middlewareUrl',
    process.env.NEXT_PUBLIC_POLYMESH_SUBQUERY_MIDDLEWARE_URL || '',
  );
  const [middlewareKey, setMiddlewareKey] = useLocalStorage<string>(
    'middlewareKey',
    process.env.POLYMESH_SUBQUERY_MIDDLEWARE_KEY || '',
  );
  const [localMetadata, setLocalMetadata] = useLocalStorage<IChainMetadata>(
    'chainMetadata',
    {
      initial: {metadata: '0x', specVersion: '', timestamp: ''},
    },
  );

  const [connecting, setConnecting] = useState<boolean | null>(null);

  const sdkRef = useRef<PolymeshSDK | null>(null);
  const nodeUrlRef = useRef<string | null>(null);
  const middlewareUrlRef = useRef<string | null>(null);
  const middlewareKeyRef = useRef<string | null>(null);
  const latestCallTimestampRef = useRef<number>(0);

  //

  const [defaultAccount, setDefaultAccount] = useLocalStorage(
    'defaultAccount',
    '',
  );
  const [rememberSelectedAccount, setRememberSelectedAccount] = useLocalStorage(
    'rememberSelectedAccount',
    true,
  );

  // All Polymesh errors should be thrown only inside Polymesh pages
  const isPolymeshRoute = router.route.includes('/polymesh');

  const initializeSigningManager = useCallback(async () => {
    const extensionList = BrowserExtensionSigningManager.getExtensionList();
    const isPolymeshExtnExist = extensionList.indexOf(POYLMESH_EXTN_NAME);
    if (!extensionList.length || isPolymeshExtnExist === -1)
      throw new Error(
        'You will need to install the Polymesh browser extension to continue.',
      );
    const browserSigningManager = await BrowserExtensionSigningManager.create({
      appName: APP_NAME, //Name of dApp used when wallet prompts to authorize connection.
      extensionName: POYLMESH_EXTN_NAME, // 'polywallet' is the default if omitted.
    });
    setSigningManager(browserSigningManager);
    return browserSigningManager;
  }, []);

  const initializeNetwork = useCallback(
    async (
      manager: BrowserExtensionSigningManager,
    ): Promise<NetworkInfo | null> => {
      return manager.getCurrentNetwork();
    },
    [],
  );

  const initializeSDK = useCallback(
    async (
      manager: BrowserExtensionSigningManager,
      network: NetworkInfo,
    ): Promise<PolymeshSDK> => {
      if (!network) throw new Error('Network not exists.');
      const {wssUrl} = network;
      return PolymeshSDK.connect({
        nodeUrl: wssUrl,
        signingManager: manager,
        middlewareV2: {
          link: process.env.NEXT_PUBLIC_POLYMESH_SUBQUERY_MIDDLEWARE_URL || '',
          key: '',
        },
      });
    },
    [],
  );

  const checkIsPrimaryAccountExists = useCallback(
    async (sdk: PolymeshSDK): Promise<string> => {
      if (!sdk) throw new Error('SDK not initialized.');
      const account = await sdk.getSigningIdentity();
      if (!account) throw new Error('Primary account not exists.');
      return account?.did as string;
    },
    [],
  );

  const connectWallet = async (): Promise<void> => {
    try {
      const manager = await initializeSigningManager();
      const NETWORK = await manager.getCurrentNetwork();
      const network = await initializeNetwork(manager);
      const sdkRef = await initializeSDK(manager, network as NetworkInfo);
      setSdk(sdkRef);
      const did = await checkIsPrimaryAccountExists(sdkRef);

      did && LocalStroageUtil.setItem(POLYMESH_LOCAL_STORAGE.DID, did);
      network &&
        LocalStroageUtil.setItem(
          POLYMESH_LOCAL_STORAGE.NODE_URL,
          network.wssUrl,
        );
      setWalletConnectionStatus(true);
    } catch (e) {
      if (isPolymeshRoute) {
        if (e instanceof Error) {
          toast.addErrorToast(e.message + 'CONNECT WALLET');
          setErrorDetails({
            isError: true,
            message: e.message,
          });
        }
      }
    }
  };

  // Commented for future use
  // Create the browser extension signing manager and connect to the Polymesh SDK.
  // const connectWalletNew = useCallback(
  //   async (extensionName: string) => {
  //     setConnecting(true);
  //     const currentTimestamp = Date.now();
  //     latestCallTimestampRef.current = currentTimestamp;
  //     try {
  //       nodeUrlRef.current = nodeUrl;
  //       middlewareUrlRef.current = middlewareUrl;
  //       middlewareKeyRef.current = middlewareKey;
  //       const signingManagerInstance =
  //         await BrowserExtensionSigningManager.create({
  //           appName: 'polymesh-portal',
  //           extensionName,
  //         });
  //       if (!sdkRef.current) {
  //         const sdkInstance = await PolymeshSDK.connect({
  //           nodeUrl,
  //           signingManager: signingManagerInstance,
  //           middlewareV2: {
  //             link: middlewareUrl,
  //             key: middlewareKey,
  //           },
  //           // polkadot: {
  //           //   noInitWarn: true,
  //           //   metadata,
  //           // },
  //         });
  //         // return if a newer call of connectWallet is in progress.
  //         if (currentTimestamp !== latestCallTimestampRef.current) {
  //           return;
  //         }
  //         sdkRef.current = sdkInstance;
  //       } else {
  //         sdkRef.current.setSigningManager(signingManagerInstance);
  //       }
  //       // We disable filtering by genesis hash for the polywallet as older releases
  //       // of the wallet incorrectly configured the genesis hash for ledger keys which
  //       // is causing issues for users.
  //       // TODO: Remove when the wallet is update to allow locking keys to a specific chain
  //       if (extensionName !== 'polywallet') {
  //         signingManagerInstance.setGenesisHash(
  //           // eslint-disable-next-line no-underscore-dangle
  //           sdkRef.current._polkadotApi.genesisHash.toString(),
  //         );
  //       }
  //       setSigningManager(signingManagerInstance);
  //       // setDefaultExtension(extensionName);
  //       setSdk(sdkRef.current);
  //       // eslint-disable-next-line no-underscore-dangle
  //       // setPolkadotApi(sdkRef.current._polkadotApi);
  //       setInitialized(true);
  //     } catch (error) {
  //       // notifyGlobalError((error as Error).message);
  //       toast.addErrorToast((error as Error).message);
  //       // this is a hacky work around for wallet errors due to chrome preloading
  //       // the page and not passing the correct url from a new tab. Preloading may
  //       // still cause authorization requests from incorrect pages requiring rejection
  //       // and manual reload
  //       if (
  //         (error as Error).message ===
  //           // error message from polywallet, polkadot.js
  //           'Invalid url chrome://newtab/, expected to start with http: or https: or ipfs: or ipns:' ||
  //         // error message from talisman extension
  //         (error as Error).message.includes('URL protocol unsupported')
  //       ) {
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1000);
  //       }
  //     } finally {
  //       setConnecting(false);
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [],
  // );

  const logout = useCallback(() => {
    setSdk(undefined);
    LocalStroageUtil.removeItem(POLYMESH_LOCAL_STORAGE.DID);
    LocalStroageUtil.removeItem(POLYMESH_LOCAL_STORAGE.NODE_URL);
  }, []);

  // TODO: Should remove unwanted lines
  useEffect(() => {
    if (!sdk) {
      return;
    }
    if (!signingManager) {
      setAllAccounts([]);
      setAllAccountsWithMeta([]);
      return () => {};
    }

    const unsubCb = signingManager.onAccountChange(async (newAccounts) => {
      try {
        if (!newAccounts.length) {
          throw new Error('No injected accounts found in the connected wallet');
        }

        const filteredNewAccounts = newAccounts as InjectedAccountWithMeta[];
        // .filter(
        //   (accountWithMeta) =>
        //     !blockedWallets.includes(accountWithMeta.address),
        // );
        if (!filteredNewAccounts.length) {
          throw new Error(
            'All injected accounts are in your blocked accounts list',
          );
        }
        setAllAccounts(
          filteredNewAccounts.map((acc) => acc.address.toString()),
        );
        setAllAccountsWithMeta(filteredNewAccounts);
      } catch (error) {
        if (error instanceof Error)
          toast.addWarningToast(error.message + 'UNSUBCB');
        setAllAccounts([]);
        setAllAccountsWithMeta([]);
      }
    }, true);

    return () => (unsubCb ? unsubCb() : undefined);
  }, [signingManager, sdk]);

  useEffect(() => {
    if (isPolymeshRoute) {
      if (sdk) return;
      (async () => {
        await connectWallet();
      })();
    }
  }, [sdk]);

  useEffect(() => {
    if (rememberSelectedAccount === true && selectedAccount) {
      setDefaultAccount(selectedAccount);
    }
  }, [rememberSelectedAccount, selectedAccount, setDefaultAccount]);

  // Set a new selected account only if the previously account
  // is no longer in the list of connected accounts
  useEffect(() => {
    if (!allAccounts.length) {
      setSelectedAccount('');
      return;
    }
    if (selectedAccount && allAccounts.includes(selectedAccount)) {
      return;
    }
    if (allAccounts.includes(defaultAccount)) {
      setSelectedAccount(defaultAccount);
      return;
    }
    setSelectedAccount(allAccounts[0]);
  }, [allAccounts, defaultAccount, selectedAccount]);

  // TODO: Should remove unwanted lines
  useEffect(() => {
    if (!sdk || !selectedAccount) {
      setAccount(null);
      return;
    }

    setAccountLoading(true);
    (async () => {
      try {
        const accountInstance = await sdk.accountManagement.getAccount({
          address: selectedAccount,
        });

        setAccount(accountInstance);
        await sdk.setSigningAccount(accountInstance);
        setShouldRefreshIdentity(true);
      } catch (error) {
        toast.addErrorToast((error as Error).message);
        setAccount(null);
      } finally {
        setAccountLoading(false);
      }
    })();
  }, [sdk, selectedAccount]);

  //
  useEffect(() => {
    if (!sdk || !allAccounts.length) {
      setAllSigningAccounts([]);
      // setKeyIdentityRelationships({});
      return;
    }

    (async () => {
      try {
        const signingAccounts =
          await sdk.accountManagement.getSigningAccounts();

        const relationshipPromises = signingAccounts.map(async (acc) => {
          const {relation} = await acc.getTypeInfo();
          const {address} = acc;
          return {address, relation};
        });

        const relationshipsArray = await Promise.all(relationshipPromises);

        const relationships: Record<string, AccountIdentityRelation> = {};
        relationshipsArray.forEach(({address, relation}) => {
          relationships[address] = relation;
        });

        setAllSigningAccounts(signingAccounts);
        // setKeyIdentityRelationships(relationships);
      } catch (error) {
        // notifyGlobalError((error as Error).message);
        if (error instanceof Error) toast.addWarningToast(error.message);
        setAllSigningAccounts([]);
        // setKeyIdentityRelationships({});
      }
    })();
  }, [allAccounts, sdk]);

  // Get identity data when sdk is initialized
  useEffect(() => {
    if (!account || !sdk || !allSigningAccounts.length) {
      setIdentity(null);
      setAllIdentities([]);
      return;
    }

    if (!shouldRefreshIdentity) return;

    (async () => {
      try {
        setIdentityLoading(true);

        const accIdentity = await account.getIdentity();
        const allAccIdentities = await Promise.all(
          allSigningAccounts.map((acc) => acc.getIdentity()),
        );

        // Place the selected account's identity at the first index of the array
        if (accIdentity !== null) {
          allAccIdentities.unshift(accIdentity);
        }
        // Filter out duplicate or null identities
        const uniqueIdentities = allAccIdentities.filter((id, index, self) => {
          return (
            id !== null &&
            index ===
              self.findIndex((otherId) => otherId && otherId.did === id.did)
          );
        });

        setIdentity(accIdentity);
        setAllIdentities(uniqueIdentities);
      } catch (error) {
        // notifyGlobalError((error as Error).message);
        if (error instanceof Error) toast.addWarningToast(error.message);
        setIdentity(null);
        setAllIdentities([]);
      } finally {
        setIdentityLoading(false);
        setShouldRefreshIdentity(false);
      }
    })();
  }, [sdk, account, shouldRefreshIdentity, allSigningAccounts]);

  const value = useMemo(
    (): PolymeshValue => ({
      initializeSigningManager,
      connectWallet,
      sdk,
      logout,
      allAccountsWithMeta,
      setSelectedAccount,
      selectedAccount,
      identity,
      identityLoading,
      allIdentities,
      allAccounts,
      setRememberSelectedAccount,
      errorDetails,
    }),
    [
      initializeSigningManager,
      connectWallet,
      sdk,
      logout,
      allAccountsWithMeta,
      setSelectedAccount,
      selectedAccount,
      identity,
      identityLoading,
      allIdentities,
      allAccounts,
      setRememberSelectedAccount,
      errorDetails,
    ],
  );
  return <Polymesh.Provider value={value}>{children}</Polymesh.Provider>;
};

export const usePolymesh = (): PolymeshValue => {
  const context = useContext(Polymesh);

  if (!context) {
    throw new Error('useLifiWidget must be used within an LifiWidgetProvider');
  }

  return context;
};
