import {ZILLIQA_DEV_TEST_NET, ZILLIQA_MAIN_NET} from '@ttx/core';
import {ENVIRONMENT} from './connectors';
import {WalletRegistrationSource, WalletNetwork} from './types';

export const registrationSources: WalletRegistrationSource[] = [
  'walletConnect',
  'metaMask',
  'venly',
  'paper',
  'zilPay',
  'magicLinkEmail',
  'magicLinkSMS',
];

export const networks: WalletNetwork[] = ['zilliqa', 'polygon', 'ethereum'];

export const APP_NAME = 'TokenTraxx';

export const AUTHENTICATION_MESSAGE_TO_SIGN =
  'Welcome to TokenTraxx! Approve this message to securely log in. Signing is a safe, gas-less transaction that does not in any way give Tokentraxx permission to perform any transactions with your wallet';

export const chainConfig = {
  ETHEREUM_CHAIN: process.env.ETHEREUM_CHAIN,
  POLYGON_CHAIN: process.env.POLYGON_CHAIN,
  ZILLIQA_CHAIN: process.env.ZILLIQA_CHAIN,
};

const openseaMainNetworkName = {
  polygon: 'matic',
  ethereum: 'ethereum',
  zilliqa: 'zilliqa',
};

const openseaTestnetNetworkName = {
  polygon: 'amoy',
  ethereum: 'ethereum',
  zilliqa: 'zilliqa',
};

export const getOpenseaUrl: (
  network: WalletNetwork,
  contractAddress: string,
  tokenId: number,
) => string = (network, contractAddress, tokenId) => {
  if (ENVIRONMENT === 'production')
    return `https://opensea.io/assets/${
      openseaMainNetworkName[network as keyof typeof openseaMainNetworkName]
    }/${contractAddress}/${tokenId}`;
  return `https://testnets.opensea.io/assets/${
    openseaTestnetNetworkName[network] as keyof typeof openseaTestnetNetworkName
  }/${contractAddress}/${tokenId}`;
};

export const polygonRpc = {
  // Mainnet
  137: 'https://polygon-rpc.com',
  // Testnet
  80002: 'https://polygon-amoy-bor-rpc.publicnode.com',
};

export const polygonWebSocketRpc = {
  // Mainnet
  137: `${process.env.POLYGON_WEBSOCKET_RPC_MAINNET}`,
  // Testnet
  80002: `${process.env.POLYGON_WEBSOCKET_RPC_TESTNET}`,
};

export const polygonWebSocketName = {
  // Mainnet
  137: 'matic',
  // Testnet
  80002: 'matic-amoy',
};

export const ethereumRpcMainnet = {
  // Mainnet
  1: `https://mainnet.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
};
export const ethereumRpcTestnets = {
  // Testnet (Ropsten)
  3: `https://ropsten.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
  // Testnet (Rinkeby)
  4: `https://rinkeby.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
  // Testnet (Goerli)
  5: `https://goerli.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
  // Testnet (Kovan)
  42: `https://kovan.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
  // Testnet (Sepolia)
  11155111: `https://sepolia.infura.io/v3/${process.env.INFURA_PROJECT_ID}`,
};

export const ethereumRpc = {
  ...ethereumRpcTestnets,
  ...ethereumRpcMainnet,
};

export const zilliqaRpc = {
  // Mainnet
  1: ZILLIQA_MAIN_NET,
  // Testnet
  333: ZILLIQA_DEV_TEST_NET,
};

export const chainNames = {
  1: 'Ethereum Mainnet',
  3: 'Ethereum Ropsten Testnet',
  4: 'Ethereum Rinkeby Testnet',
  5: 'Ethereum Goerli Testnet',
  42: 'Ethereum Kovan Testnet',
  137: 'Polygon Matic Mainnet',
  80002: 'Polygon Amoy Testnet',
};

export const ethereumProviderNames = {
  1: 'homestead',
  3: 'ropsten',
  4: 'rinkeby',
  5: 'goerli',
  42: 'kovan',
  11155111: 'sepolia',
};
